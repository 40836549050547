import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _679cec52 = () => interopDefault(import('../pages/aktivieren.vue' /* webpackChunkName: "pages/aktivieren" */))
const _067cf297 = () => interopDefault(import('../pages/angebot-beendet.vue' /* webpackChunkName: "pages/angebot-beendet" */))
const _0a019ca3 = () => interopDefault(import('../pages/app-download.vue' /* webpackChunkName: "pages/app-download" */))
const _567bbd52 = () => interopDefault(import('../pages/applogin.vue' /* webpackChunkName: "pages/applogin" */))
const _1580a7ee = () => interopDefault(import('../pages/hilfe/index.vue' /* webpackChunkName: "pages/hilfe/index" */))
const _7c6eddba = () => interopDefault(import('../pages/infomail-abmelden.vue' /* webpackChunkName: "pages/infomail-abmelden" */))
const _39fe34bf = () => interopDefault(import('../pages/infos-zum-online-kauf.vue' /* webpackChunkName: "pages/infos-zum-online-kauf" */))
const _4f324242 = () => interopDefault(import('../pages/magenta-smarthome-alexa-skill.vue' /* webpackChunkName: "pages/magenta-smarthome-alexa-skill" */))
const _9cdc0f1c = () => interopDefault(import('../pages/magenta-smarthome-app-und-funktionen/index.vue' /* webpackChunkName: "pages/magenta-smarthome-app-und-funktionen/index" */))
const _7db2c479 = () => interopDefault(import('../pages/magenta-smarthome-app-updates/index.vue' /* webpackChunkName: "pages/magenta-smarthome-app-updates/index" */))
const _a979b59a = () => interopDefault(import('../pages/magentazuhause-alexa-skill.vue' /* webpackChunkName: "pages/magentazuhause-alexa-skill" */))
const _46b4bc4d = () => interopDefault(import('../pages/magentazuhause-app.vue' /* webpackChunkName: "pages/magentazuhause-app" */))
const _39acd9e8 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _3eee9a0f = () => interopDefault(import('../pages/newsletter-abmelden.vue' /* webpackChunkName: "pages/newsletter-abmelden" */))
const _c0ca18ae = () => interopDefault(import('../pages/partner.vue' /* webpackChunkName: "pages/partner" */))
const _038f2cc4 = () => interopDefault(import('../pages/ratenzahlung.vue' /* webpackChunkName: "pages/ratenzahlung" */))
const _0cde11ae = () => interopDefault(import('../pages/schnell-starten.vue' /* webpackChunkName: "pages/schnell-starten" */))
const _cda0c20c = () => interopDefault(import('../pages/shop-start.vue' /* webpackChunkName: "pages/shop-start" */))
const _8054ef38 = () => interopDefault(import('../pages/so-geht-smart-home.vue' /* webpackChunkName: "pages/so-geht-smart-home" */))
const _92cdae2a = () => interopDefault(import('../pages/sticky-banner-alexa-skill.vue' /* webpackChunkName: "pages/sticky-banner-alexa-skill" */))
const _fa7ec0ce = () => interopDefault(import('../pages/url-generator/index.vue' /* webpackChunkName: "pages/url-generator/index" */))
const _98eac55e = () => interopDefault(import('../pages/geraete/async-data-functions.js' /* webpackChunkName: "pages/geraete/async-data-functions" */))
const _5ea04d9c = () => interopDefault(import('../pages/hilfe/faq.vue' /* webpackChunkName: "pages/hilfe/faq" */))
const _20f8e495 = () => interopDefault(import('../pages/hilfe/home-base-wechsel.vue' /* webpackChunkName: "pages/hilfe/home-base-wechsel" */))
const _9d3eaa02 = () => interopDefault(import('../pages/hilfe/kompatible-geraete/index.vue' /* webpackChunkName: "pages/hilfe/kompatible-geraete/index" */))
const _5e13563c = () => interopDefault(import('../pages/hilfe/kontakt.vue' /* webpackChunkName: "pages/hilfe/kontakt" */))
const _5fa51fa8 = () => interopDefault(import('../pages/hilfe/retoure-und-kuendigung.vue' /* webpackChunkName: "pages/hilfe/retoure-und-kuendigung" */))
const _93a27428 = () => interopDefault(import('../pages/hilfe/zentralen-und-funkstandards.vue' /* webpackChunkName: "pages/hilfe/zentralen-und-funkstandards" */))
const _607efcd5 = () => interopDefault(import('../pages/ideen/alle-ideen.vue' /* webpackChunkName: "pages/ideen/alle-ideen" */))
const _41c12cfc = () => interopDefault(import('../pages/ideen/energie-sparen.vue' /* webpackChunkName: "pages/ideen/energie-sparen" */))
const _e2effdaa = () => interopDefault(import('../pages/ideen/idea-async-data-functions.js' /* webpackChunkName: "pages/ideen/idea-async-data-functions" */))
const _0095aeb9 = () => interopDefault(import('../pages/ideen/komfort.vue' /* webpackChunkName: "pages/ideen/komfort" */))
const _3a31f537 = () => interopDefault(import('../pages/ideen/licht.vue' /* webpackChunkName: "pages/ideen/licht" */))
const _3d22a73b = () => interopDefault(import('../pages/ideen/sicherheit.vue' /* webpackChunkName: "pages/ideen/sicherheit" */))
const _44ed0afc = () => interopDefault(import('../pages/ideen/smarte-tuerschloesser-mit-nuki.vue' /* webpackChunkName: "pages/ideen/smarte-tuerschloesser-mit-nuki" */))
const _5ae6629d = () => interopDefault(import('../pages/ideen/smarter-sonnenschutz-von-eq-3.vue' /* webpackChunkName: "pages/ideen/smarter-sonnenschutz-von-eq-3" */))
const _427d3710 = () => interopDefault(import('../pages/ideen/unterhaltung.vue' /* webpackChunkName: "pages/ideen/unterhaltung" */))
const _48f10ce3 = () => interopDefault(import('../pages/ideen/wetter-und-klima-mit-netatmo.vue' /* webpackChunkName: "pages/ideen/wetter-und-klima-mit-netatmo" */))
const _0e64068f = () => interopDefault(import('../pages/magenta-smarthome-app-und-funktionen/wiz-integration.vue' /* webpackChunkName: "pages/magenta-smarthome-app-und-funktionen/wiz-integration" */))
const _5b9e3f6f = () => interopDefault(import('../pages/magenta-smarthome-app-updates/version-6.5.vue' /* webpackChunkName: "pages/magenta-smarthome-app-updates/version-6.5" */))
const _2298150a = () => interopDefault(import('../pages/shop/fehler.vue' /* webpackChunkName: "pages/shop/fehler" */))
const _68e5583e = () => interopDefault(import('../pages/shop/home-base-2-aktion.vue' /* webpackChunkName: "pages/shop/home-base-2-aktion" */))
const _25aaaaa2 = () => interopDefault(import('../pages/shop/kundenformular.vue' /* webpackChunkName: "pages/shop/kundenformular" */))
const _063231f2 = () => interopDefault(import('../pages/shop/kundenformular/index.vue' /* webpackChunkName: "pages/shop/kundenformular/index" */))
const _02358df6 = () => interopDefault(import('../pages/shop/kundenformular/identifizierung.vue' /* webpackChunkName: "pages/shop/kundenformular/identifizierung" */))
const _604c55ee = () => interopDefault(import('../pages/shop/kundenformular/login.vue' /* webpackChunkName: "pages/shop/kundenformular/login" */))
const _20db4fc7 = () => interopDefault(import('../pages/shop/kundenformular/upgrade-identifikation.vue' /* webpackChunkName: "pages/shop/kundenformular/upgrade-identifikation" */))
const _7b5eb6ee = () => interopDefault(import('../pages/shop/zuhause-im-blick-paket.vue' /* webpackChunkName: "pages/shop/zuhause-im-blick-paket" */))
const _10ae2171 = () => interopDefault(import('../pages/weitere-informationen/agb-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/agb-brodos" */))
const _f7c2ebe4 = () => interopDefault(import('../pages/weitere-informationen/sitemap.vue' /* webpackChunkName: "pages/weitere-informationen/sitemap" */))
const _18a03ace = () => interopDefault(import('../pages/weitere-informationen/ueber-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-brodos" */))
const _9576ecbc = () => interopDefault(import('../pages/weitere-informationen/ueber-qivicon.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-qivicon" */))
const _3fa5d9ea = () => interopDefault(import('../pages/hilfe/kompatible-geraete/kompa-metadata.js' /* webpackChunkName: "pages/hilfe/kompatible-geraete/kompa-metadata" */))
const _781c0680 = () => interopDefault(import('../pages/shop/checkout/adresse.vue' /* webpackChunkName: "pages/shop/checkout/adresse" */))
const _33dc6fa3 = () => interopDefault(import('../pages/shop/checkout/bestaetigung.vue' /* webpackChunkName: "pages/shop/checkout/bestaetigung" */))
const _4f4f6d2c = () => interopDefault(import('../pages/shop/checkout/bestaetigung-teaser.vue' /* webpackChunkName: "pages/shop/checkout/bestaetigung-teaser" */))
const _ae5eff94 = () => interopDefault(import('../pages/shop/checkout/warenkorb.vue' /* webpackChunkName: "pages/shop/checkout/warenkorb" */))
const _c67a3cf8 = () => interopDefault(import('../pages/shop/checkout/zahlungsart.vue' /* webpackChunkName: "pages/shop/checkout/zahlungsart" */))
const _1d4325b2 = () => interopDefault(import('../pages/shop/checkout/zusammenfassung.vue' /* webpackChunkName: "pages/shop/checkout/zusammenfassung" */))
const _62a10e06 = () => interopDefault(import('../pages/shop/dienst/mza-upgrade.vue' /* webpackChunkName: "pages/shop/dienst/mza-upgrade" */))
const _45bf0df6 = () => interopDefault(import('../pages/shop/dienst/upgrade.vue' /* webpackChunkName: "pages/shop/dienst/upgrade" */))
const _6f98097e = () => interopDefault(import('../pages/shop/verfuegbarkeitsbenachrichtigung/_token.vue' /* webpackChunkName: "pages/shop/verfuegbarkeitsbenachrichtigung/_token" */))
const _0b1669f6 = () => interopDefault(import('../pages/geraete/_slug.vue' /* webpackChunkName: "pages/geraete/_slug" */))
const _3d17760a = () => interopDefault(import('../pages/ideen/_idea.vue' /* webpackChunkName: "pages/ideen/_idea" */))
const _bec53002 = () => interopDefault(import('../pages/service/_service.vue' /* webpackChunkName: "pages/service/_service" */))
const _51e7a8f3 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _287f8280 = () => interopDefault(import('../special-pages/shop-list' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivieren",
    component: _679cec52,
    name: "aktivieren"
  }, {
    path: "/angebot-beendet",
    component: _067cf297,
    name: "angebot-beendet"
  }, {
    path: "/app-download",
    component: _0a019ca3,
    name: "app-download"
  }, {
    path: "/applogin",
    component: _567bbd52,
    name: "applogin"
  }, {
    path: "/hilfe",
    component: _1580a7ee,
    name: "hilfe"
  }, {
    path: "/infomail-abmelden",
    component: _7c6eddba,
    name: "infomail-abmelden"
  }, {
    path: "/infos-zum-online-kauf",
    component: _39fe34bf,
    name: "infos-zum-online-kauf"
  }, {
    path: "/magenta-smarthome-alexa-skill",
    component: _4f324242,
    name: "magenta-smarthome-alexa-skill"
  }, {
    path: "/magenta-smarthome-app-und-funktionen",
    component: _9cdc0f1c,
    name: "magenta-smarthome-app-und-funktionen"
  }, {
    path: "/magenta-smarthome-app-updates",
    component: _7db2c479,
    name: "magenta-smarthome-app-updates"
  }, {
    path: "/magentazuhause-alexa-skill",
    component: _a979b59a,
    name: "magentazuhause-alexa-skill"
  }, {
    path: "/magentazuhause-app",
    component: _46b4bc4d,
    name: "magentazuhause-app"
  }, {
    path: "/newsletter",
    component: _39acd9e8,
    name: "newsletter"
  }, {
    path: "/newsletter-abmelden",
    component: _3eee9a0f,
    name: "newsletter-abmelden"
  }, {
    path: "/partner",
    component: _c0ca18ae,
    name: "partner"
  }, {
    path: "/ratenzahlung",
    component: _038f2cc4,
    name: "ratenzahlung"
  }, {
    path: "/schnell-starten",
    component: _0cde11ae,
    name: "schnell-starten"
  }, {
    path: "/shop-start",
    component: _cda0c20c,
    name: "shop-start"
  }, {
    path: "/so-geht-smart-home",
    component: _8054ef38,
    name: "so-geht-smart-home"
  }, {
    path: "/sticky-banner-alexa-skill",
    component: _92cdae2a,
    name: "sticky-banner-alexa-skill"
  }, {
    path: "/url-generator",
    component: _fa7ec0ce,
    name: "url-generator"
  }, {
    path: "/geraete/async-data-functions",
    component: _98eac55e,
    name: "geraete-async-data-functions"
  }, {
    path: "/hilfe/faq",
    component: _5ea04d9c,
    name: "hilfe-faq"
  }, {
    path: "/hilfe/home-base-wechsel",
    component: _20f8e495,
    name: "hilfe-home-base-wechsel"
  }, {
    path: "/hilfe/kompatible-geraete",
    component: _9d3eaa02,
    name: "hilfe-kompatible-geraete"
  }, {
    path: "/hilfe/kontakt",
    component: _5e13563c,
    name: "hilfe-kontakt"
  }, {
    path: "/hilfe/retoure-und-kuendigung",
    component: _5fa51fa8,
    name: "hilfe-retoure-und-kuendigung"
  }, {
    path: "/hilfe/zentralen-und-funkstandards",
    component: _93a27428,
    name: "hilfe-zentralen-und-funkstandards"
  }, {
    path: "/ideen/alle-ideen",
    component: _607efcd5,
    name: "ideen-alle-ideen"
  }, {
    path: "/ideen/energie-sparen",
    component: _41c12cfc,
    name: "ideen-energie-sparen"
  }, {
    path: "/ideen/idea-async-data-functions",
    component: _e2effdaa,
    name: "ideen-idea-async-data-functions"
  }, {
    path: "/ideen/komfort",
    component: _0095aeb9,
    name: "ideen-komfort"
  }, {
    path: "/ideen/licht",
    component: _3a31f537,
    name: "ideen-licht"
  }, {
    path: "/ideen/sicherheit",
    component: _3d22a73b,
    name: "ideen-sicherheit"
  }, {
    path: "/ideen/smarte-tuerschloesser-mit-nuki",
    component: _44ed0afc,
    name: "ideen-smarte-tuerschloesser-mit-nuki"
  }, {
    path: "/ideen/smarter-sonnenschutz-von-eq-3",
    component: _5ae6629d,
    name: "ideen-smarter-sonnenschutz-von-eq-3"
  }, {
    path: "/ideen/unterhaltung",
    component: _427d3710,
    name: "ideen-unterhaltung"
  }, {
    path: "/ideen/wetter-und-klima-mit-netatmo",
    component: _48f10ce3,
    name: "ideen-wetter-und-klima-mit-netatmo"
  }, {
    path: "/magenta-smarthome-app-und-funktionen/wiz-integration",
    component: _0e64068f,
    name: "magenta-smarthome-app-und-funktionen-wiz-integration"
  }, {
    path: "/magenta-smarthome-app-updates/version-6.5",
    component: _5b9e3f6f,
    name: "magenta-smarthome-app-updates-version-6.5"
  }, {
    path: "/shop/fehler",
    component: _2298150a,
    name: "shop-fehler"
  }, {
    path: "/shop/home-base-2-aktion",
    component: _68e5583e,
    name: "shop-home-base-2-aktion"
  }, {
    path: "/shop/kundenformular",
    component: _25aaaaa2,
    children: [{
      path: "",
      component: _063231f2,
      name: "shop-kundenformular"
    }, {
      path: "identifizierung",
      component: _02358df6,
      name: "shop-kundenformular-identifizierung"
    }, {
      path: "login",
      component: _604c55ee,
      name: "shop-kundenformular-login"
    }, {
      path: "upgrade-identifikation",
      component: _20db4fc7,
      name: "shop-kundenformular-upgrade-identifikation"
    }]
  }, {
    path: "/shop/zuhause-im-blick-paket",
    component: _7b5eb6ee,
    name: "shop-zuhause-im-blick-paket"
  }, {
    path: "/weitere-informationen/agb-brodos",
    component: _10ae2171,
    name: "weitere-informationen-agb-brodos"
  }, {
    path: "/weitere-informationen/sitemap",
    component: _f7c2ebe4,
    name: "weitere-informationen-sitemap"
  }, {
    path: "/weitere-informationen/ueber-brodos",
    component: _18a03ace,
    name: "weitere-informationen-ueber-brodos"
  }, {
    path: "/weitere-informationen/ueber-qivicon",
    component: _9576ecbc,
    name: "weitere-informationen-ueber-qivicon"
  }, {
    path: "/hilfe/kompatible-geraete/kompa-metadata",
    component: _3fa5d9ea,
    name: "hilfe-kompatible-geraete-kompa-metadata"
  }, {
    path: "/shop/checkout/adresse",
    component: _781c0680,
    name: "shop-checkout-adresse"
  }, {
    path: "/shop/checkout/bestaetigung",
    component: _33dc6fa3,
    name: "shop-checkout-bestaetigung"
  }, {
    path: "/shop/checkout/bestaetigung-teaser",
    component: _4f4f6d2c,
    name: "shop-checkout-bestaetigung-teaser"
  }, {
    path: "/shop/checkout/warenkorb",
    component: _ae5eff94,
    name: "shop-checkout-warenkorb"
  }, {
    path: "/shop/checkout/zahlungsart",
    component: _c67a3cf8,
    name: "shop-checkout-zahlungsart"
  }, {
    path: "/shop/checkout/zusammenfassung",
    component: _1d4325b2,
    name: "shop-checkout-zusammenfassung"
  }, {
    path: "/shop/dienst/mza-upgrade",
    component: _62a10e06,
    name: "shop-dienst-mza-upgrade"
  }, {
    path: "/shop/dienst/upgrade",
    component: _45bf0df6,
    name: "shop-dienst-upgrade"
  }, {
    path: "/shop/verfuegbarkeitsbenachrichtigung/:token?",
    component: _6f98097e,
    name: "shop-verfuegbarkeitsbenachrichtigung-token"
  }, {
    path: "/geraete/:slug?",
    component: _0b1669f6,
    name: "geraete-slug"
  }, {
    path: "/ideen/:idea?",
    component: _3d17760a,
    name: "ideen-idea"
  }, {
    path: "/service/:service?",
    component: _bec53002,
    name: "service-service"
  }, {
    path: "/",
    component: _51e7a8f3,
    name: "index"
  }, {
    path: "/shop/:category?/:filter?/:page?",
    component: _287f8280,
    name: "shop-list"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
